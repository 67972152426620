::-webkit-scrollbar {
    width: 16px; 
    height: 12px; 
}

::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 2px; 
    /* box-shadow: inset 0 0 5px grey; */
}

::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 2px;
    border: 2px solid transparent; 
    background-clip: padding-box; 
}

/* ::-webkit-scrollbar-thumb:hover {
    background: #555; 
} */
